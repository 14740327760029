$theme-colors: (
    
    "secondary": #fbb423,
    "light" : #EDEFF0,
    "background": #15292F,
    

);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';



