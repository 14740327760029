.navibartop {
    color: var(--bs-white);
    height: 88px;
    background: rgba(193, 198, 202, 0.79) !important;
    border-color: transparent;
    opacity: 1;
    filter: sepia(0%);
    padding: 0px;
    padding-bottom: 0px;
    margin-top: -7px;
}
.element-with-space {
    margin-bottom: 10px; /* Adjust the value to control the amount of vertical space */
  }

.nav_itemtyle {
    text-align: right;
    background: transparent;
    color: white !important;
    font-weight: bold !important;
}

a:hover{
    background-color: rgba(193, 198, 202, 0.79);
}

.nav_logostyle {
    width: auto;
    height: 45px;
    margin-right: 22px;
}

.location_style {
    border-color: rgba(255, 255, 255, 0);
    background: rgba(0, 0, 0, 0)
}

.semitrans_bg {
    background: rgba(255, 255, 255, 0.95) !important;
}

.nav_itemstyle {
    color: rgb(252, 252, 252) !important;
    font-family: Montserrat, sans-serif;
    height: 59px;
    width: 171.172px;
}

.h1_first {
    padding-bottom: 50px;
    padding-top: 140px;
    margin-left: 0px;
}

.heading_font {
    font-family: Alatsi, sans-serif;
    letter-spacing: 9px !important;
    font-size: 45px !important;

}

.joinUs_font {
    color: var(--bs-white);
    font-family: Archivo, sans-serif;
    letter-spacing: 9px;
    font-size: 40px;
    background: Transparent;
    text-align: center;
    width: auto;
    height: auto;
}

.subheading_font {
    font-family: Lato;
    font-size: 25px;
}

.descriptive_p {
    margin-bottom: 80px;
    padding-bottom: 20px;
    font-family: Lato, sans-serif;
    font-size: 25px;
    letter-spacing: 3px;
}

.aboutUs_timeline {
    background: white;
    padding-top: 30px;
    padding-bottom: 55px;
    padding-right: 20px;
    padding-left: 20px;
}


.aboutUs_font {
    color: rgb(0, 0, 0);
    padding-top: 30px;
    text-align: center;
    padding-bottom: 30px;
    font-family: Archivo, sans-serif;
    letter-spacing: 8px;
    font-size: 45px;
}